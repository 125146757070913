@import "~normalize.css/normalize.css";

html {
  box-sizing: border-box;
  transition-duration: 0.15s;
  transition-timing-function: ease-out;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  transition-duration: inherit;
  transition-timing-function: inherit;
  transition-property: none;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Lato", serif;
  color: #13010b;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
pre {
  margin: 0;
  padding: 0;
}

input {
  font: inherit;
}

img {
  max-width: 100%;
  max-height: 100%;
}
